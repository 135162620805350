<template>
<v-main>
  <v-container class="mt-4">
    <v-card>
      <v-card-title>
        Kunden
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="customers"
          :options.sync="options"
          :server-items-length="totalUserCount"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      >
        <template v-slot:item.createPdf="{ item }">
          <v-btn small outlined @click="createJahresupdate(item.id)">
            Jahresupdate
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</v-main>
</template>

<script>
import moment from "moment";
export default {
  name: "RoyCustomers",
  data: () => ({
    headers: [
      {
        text: "Vorname",
        value: "vorname",
      },
      {
        text: "Nachname",
        value: "name",
      },
      {
        text: "E-Mail",
        value: "emailadressePrivat",
      },
      {
        text: "Telefon",
        value: "telefonnummerPrivat",
      },
      {
        text: "PDF erzeugen",
        value: "createPdf",
        sortable: false,
      },
    ],
    customers: [],
    options: {},
    itemsPerPageOptions: [200],
    totalUserCount: 0,
    loading: false
  }),
  created() {
    this.fetchCustomers();
  },
  methods: {
    fetchCustomers() {
      this.loading = true;
      this.$store.dispatch("getRequestPromise", "roy-customers").then(response => {
        this.loading = false;
        this.totalUserCount = response.length;
        this.customers = response
      });
    },
    createJahresupdate(userId) {
      this.$store.dispatch("postRequestPromise", ["roy-customers/" + userId + "/pdf/jahresupdate"]).then(response => {
        console.log(response);
      });
    }
  }
};
</script>

<style scoped>

</style>